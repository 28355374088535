import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,2];

export const dictionary = {
		"/[[lang=lang]]/(app)": [~8,[2,3],[,4]],
		"/[[lang=lang]]/(app)/admin": [~9,[2,3,5],[,4]],
		"/[[lang=lang]]/(app)/admin/active-analysis": [~10,[2,3,5],[,4]],
		"/[[lang=lang]]/(app)/admin/admin-impersonation-user": [~11,[2,3,5],[,4]],
		"/[[lang=lang]]/(app)/admin/changelogs": [~12,[2,3,5],[,4]],
		"/[[lang=lang]]/(app)/admin/changelogs/new": [~14,[2,3],[,4]],
		"/[[lang=lang]]/(app)/admin/changelogs/[id]": [~13,[2,3],[,4]],
		"/[[lang=lang]]/(app)/admin/new-organization": [~15,[2,3],[,4]],
		"/[[lang=lang]]/(app)/admin/organizations": [~16,[2,3,5],[,4]],
		"/[[lang=lang]]/(app)/admin/organizations/[id]": [~17,[2,3],[,4]],
		"/[[lang=lang]]/(app)/admin/organizations/[id]/analysis/[analysis_id]": [~18,[2,3],[,4]],
		"/[[lang=lang]]/(app)/admin/organizations/[id]/analysis/[analysis_id]/preview": [~19,[2,3],[,4]],
		"/[[lang=lang]]/(app)/admin/organizations/[id]/analysis/[analysis_id]/report": [~20,[2,3],[,4]],
		"/[[lang=lang]]/(analysis_public)/analysis-public/[id]": [~7,[2]],
		"/[[lang=lang]]/(app)/analysis": [~21,[2,3],[,4]],
		"/[[lang=lang]]/(app)/analysis/unauthorized": [23,[2,3],[,4]],
		"/[[lang=lang]]/(app)/analysis/[id]": [~22,[2,3],[,4]],
		"/[[lang=lang]]/(app)/changelogs": [~24,[2,3],[,4]],
		"/[[lang=lang]]/(app)/contact": [~25,[2,3],[,4]],
		"/[[lang=lang]]/(app)/deactivated": [~26,[2,3],[,4]],
		"/[[lang=lang]]/(auth)/forgot-password": [~36,[2,6]],
		"/[[lang=lang]]/(auth)/forgot-password/new-password": [~37,[2,6]],
		"/[[lang=lang]]/(auth)/forgot-password/send": [~38,[2,6]],
		"/[[lang=lang]]/(app)/full-access": [~27,[2,3],[,4]],
		"/[[lang=lang]]/(app)/imgtest": [~28,[2,3],[,4]],
		"/[[lang=lang]]/(auth)/invited": [~39,[2,6]],
		"/[[lang=lang]]/(auth)/login": [~40,[2,6]],
		"/[[lang=lang]]/(app)/messages": [~29,[2,3],[,4]],
		"/[[lang=lang]]/(app)/messages/unauthorized": [30,[2,3],[,4]],
		"/[[lang=lang]]/(app)/my-profile": [~31,[2,3],[,4]],
		"/[[lang=lang]]/(app)/new-analysis": [~32,[2,3],[,4]],
		"/[[lang=lang]]/(app)/new-analysis/unauthorized": [33,[2,3],[,4]],
		"/[[lang=lang]]/(auth)/register": [~41,[2,6]],
		"/[[lang=lang]]/(auth)/register/confirm-email": [42,[2,6]],
		"/[[lang=lang]]/(auth)/register/email-confirmed": [~43,[2,6]],
		"/[[lang=lang]]/(app)/unauthorized": [34,[2,3],[,4]],
		"/[[lang=lang]]/(app)/welcome": [~35,[2,3],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';